import _toConsumableArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _set from "lodash/set";
import _isEmpty from "lodash/isEmpty";
import _includes from "lodash/includes";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import _some from "lodash/some";
import _flatMap from "lodash/flatMap";
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { calculateProductDiscounts } from 'shared/order/calculate-product-discounts';
import { prepareCoupon } from './coupons';
import { prepareRewards } from './rewards';
import { prepareProductTotal, calculateMenuTotal, findAPlaceForResidualPennies, calculateManualDiscounts, calculateTip, calculatePostTaxSubtotal, orderProductsForDiscountBehavior, getGlobalSpecialsSettings, logWholesaleDiscount } from './utilities';
import { determineMixAndMatchAdjustments } from './mix-and-match';
import { Receipt } from './receipt';
import { taxes } from './taxes';
import { addCanadianDeliveryFeeTaxIfApplicable, calculateDeliveryFee, calculatePaymentFeeIfApplicable } from './fees';
export function computer(_ref, calculationsLogger) {
  var _dispensary$storeSett, _dispensary$storeSett2, _receipt$summary, _appliedRewards$, _window, _dispensary$useDartDi, _dispensary$storeSett3;

  var cart = _ref.cart,
      dispensary = _ref.dispensary,
      deliveryOption = _ref.deliveryOption,
      medicalOrder = _ref.medicalOrder,
      couponValue = _ref.couponValue,
      manualDiscount = _ref.manualDiscount,
      tipValue = _ref.tipValue,
      firstTimePBBUser = _ref.firstTimePBBUser,
      paymentMethod = _ref.paymentMethod,
      toMenu = _ref.toMenu,
      appliedRewards = _ref.appliedRewards;

  if (!dispensary || !cart) {
    return {};
  }
  /*
   * One thing to realize is that at this point we have prices in SpecialPrices
   * which are discount first and then tax.  The regular Prices arrays contain
   * just the tax.  So if you later want to build something a discount following
   * a tax take the regular price and apply the discount.  Or if you just want the
   * discount rip off the tax from the SpecialPrices.  The tools for building different
   * price combinations exist.
   */


  var _taxes = taxes(cart, dispensary, medicalOrder, deliveryOption === 'delivery', toMenu),
      details = _taxes.details,
      salesTaxExistence = _taxes.salesTaxExistence,
      cannabisTaxExistence = _taxes.cannabisTaxExistence,
      bottleDepositTaxExistence = _taxes.bottleDepositTaxExistence;

  var receipt = new Receipt(dispensary);
  var globalSpecialsSettings = getGlobalSpecialsSettings(dispensary);
  var roundToNearestDollar = (_dispensary$storeSett = (_dispensary$storeSett2 = dispensary.storeSettings) === null || _dispensary$storeSett2 === void 0 ? void 0 : _dispensary$storeSett2.enableRoundToNearestDollar) !== null && _dispensary$storeSett !== void 0 ? _dispensary$storeSett : false; // Check if there's any individual non-stacking special

  var allSpecials = _flatMap(details, function (detail) {
    var _detail$specialData, _detail$specialData2;

    return [].concat(_toConsumableArray(((_detail$specialData = detail.specialData) === null || _detail$specialData === void 0 ? void 0 : _detail$specialData.saleSpecials) || []), _toConsumableArray(((_detail$specialData2 = detail.specialData) === null || _detail$specialData2 === void 0 ? void 0 : _detail$specialData2.bogoSpecials) || []));
  });

  var hasIndividualNonStackingSpecial = _some(allSpecials, function (special) {
    return special.stackingMode === 'non-stacking' || special.discountStacking === false;
  });

  var hasTotalQtyCalcsFix = _some(allSpecials, 'useTotalQtyCalcsFix');
  /* sort order here will depend on the dispensaries special config
   * if the dispensary has stacking disabled with favorCustomer selected we want to sort the products from most
   * expensive to cheapest so that the discounts are larger (favor customer) otherwise we want to
   * sort the details by cheapest to most expensive menu price (includes sales discounts), so discounts / specials /
   * rewards / etc are applied to cheapest applicable items before anything else
   */


  var detailsSorted = orderProductsForDiscountBehavior(details, globalSpecialsSettings); // If Mix and Match is enabled, this will determine viable discounts, adding a mixAndMatch object with an
  // adjustedBasePrice to be used instead of basePrice for calculations later on

  detailsSorted = determineMixAndMatchAdjustments(detailsSorted, dispensary, deliveryOption === "delivery");

  var discountTaxOrder = _get(dispensary, "taxConfig.discountTaxOrder", "both"); // eslint-disable-next-line prefer-const


  var _prepareProductTotal = prepareProductTotal(detailsSorted),
      productTotal = _prepareProductTotal.productTotal,
      residualPrice = _prepareProductTotal.residualPrice;

  var credit = Big(0); // this is a workaround to prevent a duplicate line item at checkout which us a legacy artifact

  var rewardAppliedToCredit = false;
  var products = {};

  _forEach(cart, function (item) {
    return products["".concat(item.product._id, "_").concat(item.option || 'N/A')] = item;
  });
  /* eslint-disable prefer-const */


  var _prepareCoupon = prepareCoupon({
    couponValue: couponValue,
    details: detailsSorted,
    discountTaxOrder: discountTaxOrder,
    medicalOrder: medicalOrder,
    products: products
  }),
      coupon = _prepareCoupon.coupon,
      couponApplicableSubtotal = _prepareCoupon.couponApplicableSubtotal,
      couponApplicableSubtotalPlusTaxes = _prepareCoupon.couponApplicableSubtotalPlusTaxes,
      couponLeftOver = _prepareCoupon.couponLeftOver;

  var _prepareRewards = prepareRewards({
    appliedRewards: appliedRewards,
    details: details,
    products: products
  }),
      rewardApplicableSubtotal = _prepareRewards.rewardApplicableSubtotal,
      rewardApplicableSubtotalPlusTaxes = _prepareRewards.rewardApplicableSubtotalPlusTaxes,
      rewardCredit = _prepareRewards.rewardCredit,
      rewardLeftOver = _prepareRewards.rewardLeftOver,
      rewards = _prepareRewards.rewards;

  var bottleDepositTaxCentsTotal = Big(0);
  var cannabisTaxTotal = Big(0);
  var salesTaxTotal = Big(0);
  var avoidTaxes = false;
  var paymentIncentives = [];

  var _calculateProductDisc = calculateProductDiscounts({
    avoidTaxes: avoidTaxes,
    bottleDepositTaxCentsTotal: bottleDepositTaxCentsTotal,
    calculationsLogger: calculationsLogger,
    cannabisTaxTotal: cannabisTaxTotal,
    coupon: coupon,
    couponApplicableSubtotal: couponApplicableSubtotal,
    couponApplicableSubtotalPlusTaxes: couponApplicableSubtotalPlusTaxes,
    couponLeftOver: couponLeftOver,
    credit: credit,
    detailsSorted: detailsSorted,
    discountTaxOrder: discountTaxOrder,
    dispensary: dispensary,
    globalSpecialsSettings: globalSpecialsSettings,
    hasIndividualNonStackingSpecial: hasIndividualNonStackingSpecial,
    hasTotalQtyCalcsFix: hasTotalQtyCalcsFix,
    medicalOrder: medicalOrder,
    firstTimePayByBankUser: firstTimePBBUser,
    paymentType: paymentMethod,
    products: products,
    productTotal: productTotal,
    receipt: receipt,
    residualPrice: residualPrice,
    rewards: rewards,
    rewardApplicableSubtotal: rewardApplicableSubtotal,
    rewardApplicableSubtotalPlusTaxes: rewardApplicableSubtotalPlusTaxes,
    rewardAppliedToCredit: rewardAppliedToCredit,
    rewardCredit: rewardCredit,
    rewardLeftOver: rewardLeftOver,
    salesTaxTotal: salesTaxTotal,
    useDartDiscountsPath: (_dispensary$useDartDi = dispensary === null || dispensary === void 0 ? void 0 : dispensary.useDartDiscountsPath) !== null && _dispensary$useDartDi !== void 0 ? _dispensary$useDartDi : dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$storeSett3 = dispensary.storeSettings) === null || _dispensary$storeSett3 === void 0 ? void 0 : _dispensary$storeSett3.enableLLxSaleDiscountSync
  });

  avoidTaxes = _calculateProductDisc.avoidTaxes;
  bottleDepositTaxCentsTotal = _calculateProductDisc.bottleDepositTaxCentsTotal;
  cannabisTaxTotal = _calculateProductDisc.cannabisTaxTotal;
  credit = _calculateProductDisc.credit;
  paymentIncentives = _calculateProductDisc.paymentIncentives;
  productTotal = _calculateProductDisc.productTotal;
  residualPrice = _calculateProductDisc.residualPrice;
  rewardAppliedToCredit = _calculateProductDisc.rewardAppliedToCredit;
  rewardCredit = _calculateProductDisc.rewardCredit;
  salesTaxTotal = _calculateProductDisc.salesTaxTotal;

  /* eslint-enable prefer-const */
  var menuTotal = calculateMenuTotal(details, globalSpecialsSettings);

  var _findAPlaceForResidua = findAPlaceForResidualPennies(avoidTaxes, productTotal, residualPrice, cannabisTaxTotal, salesTaxTotal, receipt),
      productTotalPlusResidual = _findAPlaceForResidua.productTotal,
      cannabisTaxTotalPlusResidual = _findAPlaceForResidua.cannabisTaxTotal,
      salesTaxPlusResidual = _findAPlaceForResidua.salesTaxTotal;

  productTotal = productTotalPlusResidual;
  cannabisTaxTotal = cannabisTaxTotalPlusResidual;
  salesTaxTotal = salesTaxPlusResidual;
  var subtotalMinusCredit = productTotal.minus(credit);
  var deliveryFee = calculateDeliveryFee(deliveryOption, dispensary, subtotalMinusCredit);
  salesTaxTotal = addCanadianDeliveryFeeTaxIfApplicable(dispensary, deliveryFee, salesTaxTotal, receipt);
  salesTaxTotal = salesTaxTotal.round(2);
  cannabisTaxTotal = cannabisTaxTotal.round(2);
  credit = credit.round(2);
  rewardCredit = rewardCredit.round(2);

  var _calculatePostTaxSubt = calculatePostTaxSubtotal(productTotal, salesTaxTotal, cannabisTaxTotal, bottleDepositTaxCentsTotal, credit),
      posttaxSubtotal = _calculatePostTaxSubt.posttaxSubtotal,
      negativePosttaxSubtotal = _calculatePostTaxSubt.negativePosttaxSubtotal;

  var altBasisForZeroedSubtotal = discountTaxOrder === 'both' || discountTaxOrder === 'taxesFirst' ? productTotal.plus(cannabisTaxTotal).plus(salesTaxTotal).plus(bottleDepositTaxCentsTotal.div(100)) : productTotal; // handles case where a tip was selected, but then cash or check was selected instead after, which could
  // apply the tip erroneously

  var shouldApplyTip = tipValue && !_includes(["cash", "check"], paymentMethod);
  var tip = calculateTip(shouldApplyTip, tipValue, negativePosttaxSubtotal, altBasisForZeroedSubtotal, posttaxSubtotal);
  var paymentFee = calculatePaymentFeeIfApplicable(dispensary, paymentMethod, negativePosttaxSubtotal, altBasisForZeroedSubtotal, posttaxSubtotal, receipt);
  deliveryFee = deliveryFee.round(2);
  tip = tip.round(2);
  paymentFee.round(0);
  var total = posttaxSubtotal.plus(deliveryFee).plus(tip).plus(paymentFee.div(100));
  receipt.addFee({
    type: "delivery",
    value: deliveryFee.times(100)
  });
  receipt.addFee({
    type: "tip",
    value: tip.times(100)
  }); // If a discount exceeds the subtotal, we zero it out above.
  // Add tax back in if the dispensary calculates tax pre-discount.

  if (negativePosttaxSubtotal && (discountTaxOrder === 'both' || discountTaxOrder === 'taxesFirst')) {
    total = total.plus(cannabisTaxTotal).plus(salesTaxTotal).plus(bottleDepositTaxCentsTotal.div(100));
  }
  /*
   * These are hand discounts applied by the dispensary.  The dispensary can
   * do whatever they want for a discount up to making the order free.
   */


  if (manualDiscount) {
    var _calculateManualDisco = calculateManualDiscounts(manualDiscount, total, receipt),
        newCredit = _calculateManualDisco.newCredit;

    total = total.minus(newCredit);
    credit = credit.plus(newCredit);
  } // remove reward from credit for discount line item


  if (rewardAppliedToCredit) {
    credit = credit.minus(rewardCredit);
  }

  var roundingDiff = Big(0);

  if (roundToNearestDollar) {
    var roundedTotal = total.round(0);
    roundingDiff = roundedTotal.minus(total).times(100);
    receipt.addRounding({
      type: "checkoutTotal",
      value: roundingDiff
    });
    total = roundedTotal;
  }

  receipt.finalize(total);
  var mixAndMatchTotal = Big((receipt === null || receipt === void 0 ? void 0 : (_receipt$summary = receipt.summary) === null || _receipt$summary === void 0 ? void 0 : _receipt$summary.mixAndMatch) || 0).div(100).round(2);
  logWholesaleDiscount({
    calculationsLogger: calculationsLogger,
    credit: credit
  });
  var calculationsOutput = {
    menuTotal: Number(menuTotal),
    cartSubtotal: Number(productTotal),
    subtotal: Number(productTotal),
    bottleDepositTaxCents: Number(bottleDepositTaxCentsTotal),
    cannabisTax: Number(cannabisTaxTotal),
    tax: Number(salesTaxTotal),
    deliveryFee: Number(deliveryFee),
    credit: Number(credit),
    paymentIncentives: paymentIncentives,
    mixAndMatch: Number(mixAndMatchTotal),
    reward: Number(rewardCredit),
    tip: Number(tip),
    paymentFee: Math.trunc(Number(paymentFee)),
    rounding: Number(roundingDiff),
    total: Number(total),
    details: details,
    lineitems: [{
      type: "productSubtotal",
      value: Number(productTotal)
    }, {
      type: "mixAndMatch",
      value: Number(mixAndMatchTotal)
    }, {
      type: "discount",
      value: Number(credit)
    }, {
      type: "reward",
      value: Number(rewardCredit),
      brand: !_isEmpty(appliedRewards) ? (_appliedRewards$ = appliedRewards[0]) === null || _appliedRewards$ === void 0 ? void 0 : _appliedRewards$.brand : "Reward"
    }, {
      type: 'paymentFee',
      value: Math.trunc(Number(paymentFee))
    }, {
      type: 'deliveryFee',
      value: Number(deliveryFee)
    }, {
      type: 'tip',
      value: Number(tip)
    }, {
      type: 'bottleDepositTaxCents',
      value: Number(bottleDepositTaxCentsTotal)
    }, {
      type: 'cannabisTax',
      value: Number(cannabisTaxTotal)
    }, {
      type: 'salesTax',
      value: Number(salesTaxTotal)
    }, {
      type: 'rounding',
      value: Number(roundingDiff / 100)
    }, {
      type: 'total',
      value: Number(total)
    }],
    salesTaxExistence: salesTaxExistence,
    cannabisTaxExistence: cannabisTaxExistence,
    bottleDepositTaxExistence: bottleDepositTaxExistence,
    receipt: receipt.toJSON()
  };

  if (typeof window !== 'undefined' && (_window = window) !== null && _window !== void 0 && _window._____APP_STATE_____) {
    _set(window._____APP_STATE_____, 'debugging.calculations', calculationsOutput);
  }

  return calculationsOutput;
}