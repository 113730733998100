/* eslint-disable @typescript-eslint/naming-convention,camelcase */
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import { ValidationError } from 'yup';
import { ProductsProvider } from 'types/graphql';

import { getDataSharingEnabledForUser } from 'utils/fides/helpers';

import { getPersistedValue } from 'shared/utils/persisted-values';

import { createTelemetryClient } from '../../clients';
import { EventTracker, AnalyticsContext } from '../../events';

import { Data, eventsSchema, validateSchema, placedOrderCartItemSchema } from './schemas';
import {
  getCommonProps,
  getItemProps,
  getCartItemProps,
  getCartTotalValue,
  getOrderProps,
  getPlacedOrderCartItemProps,
} from './utils';

const loggingEnabled = !!getPersistedValue('analytics:telemetryTracker');

function checkTelemetryEnabledForDispo(context: AnalyticsContext): boolean {
  const adEnabled = Object.values(context.activeDispensary?.adSettings ?? {}).includes(true);
  const personalizationEnabled = Object.values(context.activeDispensary?.personalizationSettings ?? {}).includes(true);

  return (
    adEnabled || personalizationEnabled || context.featureFlags[`growth.ads.personalization.sort-by-relevance.rollout`]
  );
}

function checkTelemetryEnabledForUser(context: AnalyticsContext): boolean {
  return getDataSharingEnabledForUser(context.featureFlags[`growth.ads.consent-management.rollout`]);
}

function checkTelemetryEnabledForEvent(context: AnalyticsContext, event: string): boolean {
  const { disabledEvents = [] } = context.featureFlags[`growth.ecomm.telemetry.event-kill-switch.operational`] ?? {};

  if (event === 'onContextChange') {
    return !disabledEvents.includes('viewedPage');
  }

  return !disabledEvents.includes(event);
}

function removeQueryParams(path = ''): string {
  const index = path.indexOf('?');
  return index !== -1 ? path.slice(0, index) : path;
}

export function decorate(eventTracker: EventTracker): EventTracker {
  const decorated = { ...eventTracker };

  Object.entries(eventTracker).forEach(([event, handler]) => {
    if (!(event in eventsSchema || event === 'onContextChange')) {
      return;
    }

    decorated[event] = (payload: any, context: AnalyticsContext, oldContext: AnalyticsContext) => {
      if (
        !context.featureFlags[`growth.ads.user-events.enabled`] ||
        !checkTelemetryEnabledForEvent(context, event) ||
        !checkTelemetryEnabledForDispo(context) ||
        !checkTelemetryEnabledForUser(context) ||
        context.activeMenuVariant === 'plus'
      ) {
        return;
      }

      try {
        handler(payload, context, oldContext);
      } catch (err) {
        if (err instanceof ValidationError && loggingEnabled) {
          console.error(err);
        } else if (!(err instanceof ValidationError)) {
          throw err;
        }
      }
    };
  });

  return decorated;
}

export const createTelemetryTracker = (): EventTracker => {
  const client = createTelemetryClient();

  return decorate({
    onContextChange: (_updates, newContext, oldContext) => {
      const { activePath } = newContext;

      const activeBasePath = removeQueryParams(activePath?.concrete);
      const oldBasePath = removeQueryParams(oldContext.activePath?.concrete);

      if (activeBasePath !== oldBasePath) {
        const data: Data = {
          type: 'viewedPage',
          payload: getCommonProps(newContext),
        };

        validateSchema(data);
        void client.post(data);
      }
    },

    cartViewed: (_payload, context) => {
      const data: Data = {
        type: 'cartViewed',
        payload: getCommonProps(context),
      };

      validateSchema(data);
      void client.post(data);
    },

    addedProductToCart: (payload, context) => {
      const data: Data = {
        type: 'addedProductToCart',
        payload: {
          ...getCommonProps(context),
          item: getCartItemProps(payload),
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    removedProductFromCart: (payload, context) => {
      const data: Data = {
        type: 'removedProductFromCart',
        payload: {
          ...getCommonProps(context),
          item: getCartItemProps(payload),
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    placedOrder: (payload, context) => {
      // exclude invalid items
      const items = _map(context.activeOrder?.cart, getPlacedOrderCartItemProps).filter((item: unknown) =>
        placedOrderCartItemSchema.isValidSync(item)
      );

      const commonProps = getCommonProps(context);
      const orderProps = getOrderProps(payload.order);

      const data: Data = {
        type: 'placedOrder',
        payload: {
          ...commonProps,
          items,
          total: getCartTotalValue(context),
          ...orderProps,
        },
      };

      validateSchema(data);
      void client.post(data, { resetSessionOnSuccess: commonProps.menu_variant === 'kiosk' });
    },

    searchResults: (payload, context) => {
      const data: Data = {
        type: 'searchResults',
        payload: {
          ...getCommonProps(context),
          query: payload.query,
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    viewedProduct: (payload, context) => {
      const data: Data = {
        type: 'viewedProduct',
        payload: {
          ...getCommonProps(context),
          item: getItemProps(payload),
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    sponsoredBannerImpression: (payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { products, impressionUrl } = payload;

      const data: Data = {
        type: 'sponsoredBannerImpression',
        payload: {
          ...getCommonProps(context),
          tracking_url: impressionUrl,
          items: products.map((item) => getItemProps({ product: item })),
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    sponsoredBannerClicked: (payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { products, clickUrl } = payload;

      const data: Data = {
        type: 'sponsoredBannerClicked',
        payload: {
          ...getCommonProps(context),
          tracking_url: clickUrl,
          items: products.map((item) => getItemProps({ product: item })),
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    sponsoredProductImpression: (payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { product } = payload;

      const data: Data = {
        type: 'sponsoredProductImpression',
        payload: {
          ...getCommonProps(context),
          tracking_url: product.adTrackers?.impressionUrl,
          item: getItemProps({ product }),
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    sponsoredProductClicked: (payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { product } = payload;

      const data: Data = {
        type: 'sponsoredProductClicked',
        payload: {
          ...getCommonProps(context),
          tracking_url: product.adTrackers?.clickUrl,
          item: getItemProps({ product }),
        },
      };

      validateSchema(data);
      void client.post(data);
    },

    productClicked: (payload, context) => {
      const { product } = payload;

      let productProvider = ProductsProvider.dutchie;

      if ('provider' in product && product.provider) {
        productProvider = product.provider;
      }

      const data: Data = {
        type: 'productClicked',
        payload: {
          ...getCommonProps(context),
          placement: payload.placement,
          position: payload.position,
          provider: productProvider,
          item: getItemProps({ product }),
        },
      };

      validateSchema(data);
      void client.post(data);
    },
    // Noop events
    accountLogin: _noop,
    buyItAgainImpression: _noop,
    buyItAgainViewAllClicked: _noop,
    checkoutStarted: _noop,
    viewedCheckout: _noop,
    checkoutSession: _noop,
    searchedForProduct: _noop,
    imageBannerClicked: _noop,
    paymentsError: _noop,
    privacyPreferenceUpdated: _noop,
    dutchiePayEnrollmentButtonClicked: _noop,
    dutchiePayLearnMoreButtonClicked: _noop,
    dutchiePayCTAButtonClicked: _noop,
    dutchiePayEnrollmentStep: _noop,
    dutchiePayConnectBankStep: _noop,
    dutchiePayPlaidOnEvent: _noop,
    dutchiePayInstoreAccountLinkViewed: _noop,
    dutchiePayInstoreLoginViewed: _noop,
    dutchiePayInstoreCartViewed: _noop,
    dutchiePayInstoreCartApprovalClicked: _noop,
    dutchiePayInstoreCartApprovalSucceeded: _noop,
    dutchiePayInstoreCartApprovalFailed: _noop,
    gaGTMClickEvent: _noop,
  });
};
