import { ProductCardProduct } from './product-card.types';
import { CARD_SIZES, CardSizes, Dimensions } from './product-card.constants';

export function getBrandName(product: ProductCardProduct): string {
  if ('brand' in product && typeof product.brand.name === 'string') {
    return product.brand.name;
  }

  if (typeof product.brandName === 'string') {
    return product.brandName;
  }

  return '';
}

export function useCardDimensions({ size = `default`, gutter = 0 } = {}): Dimensions {
  let dimensions: Dimensions = CARD_SIZES.default;

  if (size && size in CardSizes) {
    dimensions = CARD_SIZES[size];
  }

  if (gutter && gutter > -1) {
    dimensions = { ...dimensions, gutter };
  }

  return dimensions;
}
