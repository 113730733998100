import { sanitizeUrl } from '@braintree/sanitize-url';

const allowedRedirectPrefixes = ['https://dutchie.com/', 'com.dutchie.retailer-sample://'];

type Params = {
  token?: string;
  query?: {
    redirectUrl?: string;
  };
  isMobileEcommApp: boolean;
};

export function redirectUrlForMobileEcomm({ isMobileEcommApp, query, token }: Params): string | null {
  if (!token?.length) {
    return null;
  }

  const redirectUrl = sanitizeUrl(query?.redirectUrl ?? '');

  const shouldRedirect = isMobileEcommApp && !!redirectUrl.length;
  if (!shouldRedirect) {
    return null;
  }

  const redirectAllowed = allowedRedirectPrefixes.some((s) => redirectUrl.startsWith(s));
  if (!redirectAllowed) {
    return null;
  }

  return `${redirectUrl}?token=${token}`;
}
