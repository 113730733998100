/* eslint-disable no-redeclare */
import { LDClient } from 'launchdarkly-react-client-sdk';

/*
 * List of feature flags that are attached to Funnel Experiments with the Ecomm Analytics Funnel Metrics Group
 */

let ldClient: LDClient | undefined;

const initialize = (client: LDClient): void => {
  ldClient = client;
};

const track: LDClient['track'] = (metric, data, metricValue) => {
  if (!ldClient) {
    console.error('ldClient not initialized');
    return;
  }

  ldClient.track(metric, data, metricValue);
};

function trackMetric(metric: string): void;
function trackMetric(metric: string, value: number): void;
function trackMetric(metric: string, value: Record<string, any>): void;
function trackMetric(metric: string, value?: Record<string, any> | number): void {
  if (!value) {
    track(metric);
  } else if (typeof value === 'number') {
    track(metric, undefined, value);
  } else {
    track(metric, value);
  }
}

type Client = {
  initialize: typeof initialize;
  trackMetric: typeof trackMetric;
};

export function createLDClient(): Client {
  return {
    initialize,
    trackMetric,
  };
}
