import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { CallbackRef } from 'use-is-in-viewport';

import { Product } from 'src/utils/helpers/product';

import useUI from 'src/hooks/use-ui';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { tracker } from 'src/utils/analytics';
import { ProductPlacement } from 'src/types';

import { CarouselProductCard } from 'src/components/product-card';
import EmptyLoadingState from 'components/empty-loading-state';

import { CARD_SIZES } from 'src/components/product-card/product-card.constants';
import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import { Content, Heading, GroupHeader, Slider, Item } from './product-carousel.styles';

import { CarouselConfigurationContext } from '../carousels/carousels.utils';

const DEFAULT_MARGINS = {
  nudge: 100,
  mobile: 0,
  smallTablet: 0,
  tablet: 0,
  largeTablet: 0,
  desktop: 0,
};

export type ProductCarouselProps = {
  isMini?: boolean;
  heading: string;
  loading: boolean;
  minimumProducts?: number;
  products: Product[];
  trackerSource?: ProductRecommendationSource;
  trackerSection?: ProductRecommendationSection;
  viewportVisibility: {
    ref: CallbackRef;
    hasBeenVisible: boolean;
  };
  hideSponsoredTags?: boolean;
  placement?: ProductPlacement;
};

export function ProductCarousel({
  heading,
  loading,
  isMini = false,
  minimumProducts = 0,
  products,
  trackerSource = ProductRecommendationSource.native,
  trackerSection = ProductRecommendationSection.custom,
  viewportVisibility: { ref, hasBeenVisible },
  placement,
  hideSponsoredTags = true,
}: ProductCarouselProps): JSX.Element | null {
  const UI = useUI();
  const router = useRouter();
  const source = getItemListName({
    router,
    source: trackerSource,
    section: trackerSection,
  });
  const { handleAddToCart } = useQuickAddToCart();

  const { useLazyLoading } = useContext(CarouselConfigurationContext);

  // Render if Lazy Loading is disabled.
  // If lazy loading is disabled, render if been visible.
  const shouldRenderCarousel = !useLazyLoading || hasBeenVisible;

  if (shouldRenderCarousel && !loading && (!products.length || products.length < minimumProducts)) {
    return null;
  }

  return (
    <div ref={ref} style={{ minHeight: isMini ? '300px' : '420px' }}>
      {shouldRenderCarousel && (
        <EmptyLoadingState isEmpty={false} isLoading={loading} page='productCarousel'>
          <Content isMini={isMini}>
            <GroupHeader>
              <Heading isMini={isMini}>{heading}</Heading>
            </GroupHeader>

            <Slider isMini={isMini} margins={DEFAULT_MARGINS}>
              {products.map((product, index) => (
                <Item key={product.id}>
                  <CarouselProductCard
                    dimensions={CARD_SIZES.default}
                    onClick={() => {
                      tracker.setContext({
                        activeProductPosition: index,
                      });

                      UI.activeProductSource = source;
                    }}
                    onQuickAddClick={() => {
                      tracker.setContext({
                        activeProductPosition: index,
                      });
                      void handleAddToCart(product, source);
                    }}
                    product={product}
                    productIndex={index}
                    trackerSource={source}
                    placement={placement}
                    hideSponsoredTags={hideSponsoredTags}
                  />
                </Item>
              ))}
            </Slider>
          </Content>
        </EmptyLoadingState>
      )}
    </div>
  );
}
