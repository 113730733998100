import _noop from 'lodash/noop';
import _isNil from 'lodash/isNil';
import { EventTracker } from '../events';
import { createLDClient } from '../clients';
import { AnalyticsOrder } from '..';

const getOrderReceiptSummaryValue = ({ receipt }: AnalyticsOrder, key: string): number | undefined => {
  const value = receipt?.summary?.[key];

  if (_isNil(value)) {
    return undefined;
  }

  return value / 100;
};

export const createLDTracker = (): EventTracker => {
  const client = createLDClient();

  return {
    onContextChange: ({ ldClient }) => {
      if (ldClient) {
        client.initialize(ldClient);
      }
    },
    cartViewed: () => {
      client.trackMetric('growth.ecomm.analytics.cart-viewed');
    },
    addedProductToCart: () => {
      client.trackMetric('growth.ecomm.analytics.added-product-to-cart');
    },
    productClicked: () => {
      client.trackMetric('growth.ecomm.analytics.product-clicked');
    },
    sponsoredProductClicked: () => {
      client.trackMetric('growth.ecomm.analytics.sponsored-product-clicked');
    },
    placedOrder: (payload) => {
      client.trackMetric('growth.ecomm.analytics.placed-order');

      const totalValue = getOrderReceiptSummaryValue(payload.order, 'total');

      if (totalValue) {
        client.trackMetric('growth.ecomm.analytics.placed-order.total-value', totalValue);
      }
    },
    // Noop events
    removedProductFromCart: _noop,
    searchResults: _noop,
    viewedProduct: _noop,
    sponsoredBannerImpression: _noop,
    sponsoredBannerClicked: _noop,
    sponsoredProductImpression: _noop,
    accountLogin: _noop,
    buyItAgainImpression: _noop,
    buyItAgainViewAllClicked: _noop,
    checkoutStarted: _noop,
    viewedCheckout: _noop,
    checkoutSession: _noop,
    searchedForProduct: _noop,
    imageBannerClicked: _noop,
    paymentsError: _noop,
    privacyPreferenceUpdated: _noop,
    dutchiePayEnrollmentButtonClicked: _noop,
    dutchiePayLearnMoreButtonClicked: _noop,
    dutchiePayCTAButtonClicked: _noop,
    dutchiePayEnrollmentStep: _noop,
    dutchiePayConnectBankStep: _noop,
    dutchiePayPlaidOnEvent: _noop,
    dutchiePayInstoreAccountLinkViewed: _noop,
    dutchiePayInstoreLoginViewed: _noop,
    dutchiePayInstoreCartViewed: _noop,
    dutchiePayInstoreCartApprovalClicked: _noop,
    dutchiePayInstoreCartApprovalSucceeded: _noop,
    dutchiePayInstoreCartApprovalFailed: _noop,
    gaGTMClickEvent: _noop,
  };
};
