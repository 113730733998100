import { useCallback } from 'react';
import { values } from 'mobx';

import { usePersonalizedItems, Scenarios, GetRecommendationsFallback } from 'src/recommendations';
import { ProductCarouselProps } from 'src/components/product-carousel';

import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import useCart from 'src/hooks/use-cart';
import { usePrependWithSponsoredProducts } from 'src/utils/ads/hooks/sponsored-products';
import { SponsoredProductsInventoryId } from 'types/graphql';
import { useShouldSkipLegacyPersonalization } from 'src/dispensary/hooks/use-skip-ads-rules';
import { useAdsEnabled } from 'src/utils/ads/hooks/use-ads-enabled';

import { OFTEN_PURCHASED_WITH_FILTERS } from 'src/components/carousels';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

type UseCartRecommendationsReturn = Omit<ProductCarouselProps, 'heading'>;

function useGetProductsFallback(type: string): GetRecommendationsFallback {
  if (!(type in OFTEN_PURCHASED_WITH_FILTERS)) {
    throw new Error(`missing often purchased with mapping for category: ${type}`);
  }

  const types = OFTEN_PURCHASED_WITH_FILTERS[type];

  return useCallback<GetRecommendationsFallback>((getProducts) => getProducts({ types, sortBy: `type` }), [types]);
}

const validExperimentAdsPlacements = new Set<LDFlagValue>(['cart_topper', 'both']);

export function useCartRecommendationsCarousel(): UseCartRecommendationsReturn {
  const { items: cartItems } = useCart();
  const flags = useFlags();
  const carouselFlagValue = flags['growth.ads.show_sponsored_products_in_carousels.experiment'] ?? false;
  const validExperimentAdsPlacement = validExperimentAdsPlacements.has(carouselFlagValue);

  const cartItemsArray = values(cartItems);
  const latestProduct = cartItemsArray.slice(-1)[0].product;
  const productIds = cartItemsArray.map((item) => item.id);
  const adsEnabled = useAdsEnabled();
  const skipAds = !adsEnabled || !validExperimentAdsPlacement;
  const skipPersonalization = useShouldSkipLegacyPersonalization();

  const viewportVisibility = useViewportVisibility();
  const { hasBeenVisible } = viewportVisibility;

  const getProductsFallback = useGetProductsFallback(latestProduct.type);

  const { data: organicRecommendations, loading: organicLoading } = usePersonalizedItems(
    getProductsFallback,
    productIds,
    Scenarios.cart1,
    {
      skip: skipPersonalization || hasBeenVisible || !productIds.length,
      limit: 25,
    }
  );

  const { products: sponsoredAndOrganicProducts, loading: sponsoredLoading } = usePrependWithSponsoredProducts({
    skip: skipAds,
    products: organicRecommendations ?? [],
    loading: organicLoading,
    error: undefined,
    limit: 5,
    inventoryId: SponsoredProductsInventoryId.cartTopperProductAuc,
    searchQuery: undefined,
  });

  const hideSponsoredTags = skipAds || !sponsoredAndOrganicProducts.some((product) => !!product.adTrackers);

  return {
    products: sponsoredAndOrganicProducts,
    loading: organicLoading || sponsoredLoading,
    viewportVisibility,
    placement: 'cart-drawer-cart-recommendations-carousel',
    hideSponsoredTags,
  };
}
