import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';

import useTranslation from 'hooks/use-translation';
import { ModalExitIcon, ModalFooter } from 'components/core';
import { ModalContent, HeaderText, ModalFooterText, SignUpText } from 'components/modals/components';

import { useModals, ModalNames } from 'components/modals';
import LoginForm from 'components/forms/login-form';
import useUI from 'src/hooks/use-ui';
import useUser from 'src/hooks/use-user';
import { Divider } from 'src/components/divider';
import { GoogleLogin } from 'src/components/google-login';
import { redirectUrlForMobileEcomm } from 'src/components/modals/login/redirect-mobile-ecomm';

export type LoginModalContentProps = {
  onClose: () => void;
  analyticsEventLabel?: string | null;
};

export function LoginModalContent({ onClose, analyticsEventLabel = null }: LoginModalContentProps): JSX.Element {
  const { openModal } = useModals();
  const { t } = useTranslation();
  const { token } = useUser();
  const { query } = useRouter();
  const { isMobileEcommApp } = useUI();
  const flags = useFlags();
  const isGoogleLoginEnabled = flags[`growth.ecomm.sign-in-with-google.rollout`] ?? false;

  const handleOpenSignUpModal = (): void => {
    onClose();
    void openModal(ModalNames.signUp, analyticsEventLabel);
  };

  useEffect(() => {
    const url = redirectUrlForMobileEcomm({ isMobileEcommApp, query, token });
    if (url) {
      window.location.href = url;
    }
  }, [isMobileEcommApp, query, token]);

  return (
    <>
      {!isMobileEcommApp && <ModalExitIcon onClick={onClose} />}
      <ModalContent data-testid='login-modal' pb={40}>
        <HeaderText pt={40} mb={25} mt={0}>
          {t('loginModal.header', 'Log in to your account')}
        </HeaderText>

        {isGoogleLoginEnabled && (
          <>
            <GoogleLogin redirectUrl={window.location.href} />
            <Divider text='or login with your email' />
          </>
        )}

        <LoginForm onClose={onClose} analyticsEventLabel={analyticsEventLabel} />
      </ModalContent>
      {!isMobileEcommApp && (
        <ModalFooter>
          <ModalFooterText>{t('loginModal.footerText', 'New to Dutchie?')}</ModalFooterText>
          <StyledSignUpText data-testid='sign-up-link' onClick={handleOpenSignUpModal}>
            {t('loginModal.footerSignUp', 'Sign Up')}
          </StyledSignUpText>
        </ModalFooter>
      )}
    </>
  );
}

const StyledSignUpText = styled(SignUpText)`
  margin-left: 0;
  padding-left: 5px;
`;
