import React from 'react';

import useUI from 'hooks/use-ui';

import { RethinkPayOrderProcessing } from 'src/payments/modals/rethink-pay/order-processing';
import { RethinkPayPaymentProcessing } from 'src/payments/modals/rethink-pay/payment-processing';
import { TermsModal } from 'src/payments/modals/terms-modal';

import { DutchiePayCtaModal } from './dutchie-pay/cta';
import { DutchiePayRelinkBankAccountModal } from './dutchie-pay/relink-bank-account';
import { DutchiePayVisitOnDesktopModal } from './dutchie-pay/visit-on-desktop';
import { ModalNames } from './modals.types';

import { AddressRequired } from './address-required';
import { EmptyCart } from './empty-cart';
import { Login } from './login';
import { ResetPassword } from './reset-password';
import { SignUp } from './sign-up';
import { ChangePassword } from './change-password';
import { ClearYourCart } from './clear-your-cart';
import { OrderType } from './order-type';
import { DeliveryAddress } from './delivery-address';
import { OrderReceipt } from './order-receipt';
import { AddToCart } from './add-to-cart';
import { OrderStatus } from './order-status-modal';
import { DispensaryStrainTypeFilterDrawer } from './strain-type-filter-drawer';
import { DistanceFilterBottomDrawer as ConsumerDistanceFilter } from './distance-filter-bottom-drawer/consumer';
import { ClosedBut } from './closed-but';
import { StoreSwitched } from './store-switched';
import { IdleWarning } from './idle-warning';
import { CompleteAccount } from './complete-account';
import { Loyalty } from './loyalty';

export const Modals = (): JSX.Element | null => {
  const UI = useUI();

  if (UI.isDemo) {
    return null;
  }

  return (
    <>
      <AddressRequired name={ModalNames.addressRequired} />
      <ChangePassword name={ModalNames.changePassword} />
      <ConsumerDistanceFilter name={ModalNames.consumerDistanceFilter} />
      <DeliveryAddress name={ModalNames.deliveryAddress} />
      <EmptyCart name={ModalNames.emptyCart} />
      <Login name={ModalNames.login} />
      <OrderReceipt name={ModalNames.orderReceipt} />
      <OrderStatus name={ModalNames.orderStatus} />
      <OrderType name={ModalNames.orderType} />
      <ResetPassword name={ModalNames.resetPassword} />
      <SignUp name={ModalNames.signUp} />
      <DutchiePayCtaModal name={ModalNames.dutchiePayCta} />
      <DutchiePayRelinkBankAccountModal name={ModalNames.dutchiePayRelinkBankAccount} />
      <DutchiePayVisitOnDesktopModal name={ModalNames.dutchiePayVisitOnDesktop} />
      <RethinkPayOrderProcessing name={ModalNames.rethinkPayOrderProcessing} />
      <RethinkPayPaymentProcessing name={ModalNames.rethinkPayPaymentProcessing} />
      <TermsModal name={ModalNames.termsOfService} />
      {/* withModalConrolsHook modals */}
      <ClearYourCart />
      <DispensaryStrainTypeFilterDrawer />
      <AddToCart />
      <ClosedBut />
      <StoreSwitched />
      <IdleWarning />
      <CompleteAccount />
      <Loyalty />
    </>
  );
};
