import { useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { LDContext } from 'launchdarkly-js-client-sdk';
import { v4 as uuidV4 } from 'uuid';

import { isBrowser } from 'utils/misc-utils';
import useUser from 'hooks/use-user';
import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import useCart from 'hooks/use-cart';
import useFeatureFlags from 'hooks/use-feature-flags';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import usePersistedValue from 'hooks/use-persisted-value';
import meConsumer from 'shared/graphql/user/queries/me-consumer.gql';
import { getPersistedValue, setPersistedValue, removePersistedValue } from 'shared/utils/persisted-values';
import { MARKETING_CAMPAIGN_PARAMS } from 'shared/constants';
import ROUTES from 'src/routes';
import { GqlMeConsumerQuery } from 'types/graphql';
import { useCheckAgeVerification } from 'src/dispensary/core-menu/age-verification-gate/hooks';
import { tracker } from 'utils/analytics';

export function useLaunchDarklyClientContext({ user, dispensary }): void {
  const FeatureFlags = useFeatureFlags();

  const userId = user?.id;
  // always default to getting the persisted value
  let userKey = getPersistedValue(`launch-darkly-client-id`, null);
  // if no persisted id is found generate a new key
  if (!userKey) {
    userKey = userId || uuidV4();
  }

  useEffect(() => {
    // get the current stored value
    const storedUserId = getPersistedValue(`launch-darkly-client-id`, null);
    // may not exist if first time visiting site or local storage was cleared
    if (!storedUserId) {
      setPersistedValue(`launch-darkly-client-id`, userKey);
    }
  }, [userKey]);

  const client = useLDClient();
  const enterpriseId = dispensary?.retailer?.enterpriseId;
  const dispensaryId = dispensary?.id;
  const isCanaryUser = getPersistedValue(`launch-darkly-canary-user`, false);

  useEffect(() => {
    const context: LDContext = {
      kind: `multi`,
      user: {
        kind: 'user',
        key: userKey,
        type: userId ? 'customer' : 'guestCustomer',
        canary: isCanaryUser,
      },
    };

    if (dispensaryId) {
      context[`ecomm-dispensary`] = {
        key: dispensaryId,
        // cName: dispensary.cName,
        // chain: dispensary.chain,
      };
    }

    if (enterpriseId) {
      context[`ecomm-enterprise`] = {
        key: enterpriseId,
      };
    }

    if (!client) {
      return;
    }

    tracker.setContext({ ldClient: client });

    void client.identify(context).then(() => {
      FeatureFlags.identify(context);
    });
  }, [client, userId, dispensaryId, enterpriseId, isCanaryUser, FeatureFlags, userKey]);
}

export function useSetSessionUtm({ dispensary }): any {
  const dispensaryId = dispensary?.id;
  const { query } = useRouter();

  const utmData = useMemo(() => {
    const utmParameters = Object.keys(query).reduce((acc, key) => {
      if (MARKETING_CAMPAIGN_PARAMS.includes(key)) {
        acc[key] = query[key];
      }
      return acc;
    }, {});
    return utmParameters;
  }, [query]);

  useEffect(() => {
    if (!dispensaryId) {
      return;
    }

    const existingUtmSessionData = getPersistedValue('utmSessionData', null);

    if (existingUtmSessionData?.dispensaryId !== dispensaryId) {
      removePersistedValue('utmSessionData', true);
    }

    if (Object.keys(utmData).length === 0) {
      return;
    }

    const utmSessionData = {
      parameters: utmData,
      dispensaryId,
    };

    setPersistedValue('utmSessionData', utmSessionData, true);
  }, [dispensaryId, utmData]);
}

export function useUserQuery({ user, cart }): void {
  const token = usePersistedValue(user, 'token', 'access-token', false);
  const refetchRequest = useObserver(() => user.refetchRequest);
  const apolloClient = useApolloClient();

  useEffect(() => {
    async function checkUser(): Promise<void> {
      user.loading = true;

      if (token && (!user.isLoggedIn || refetchRequest)) {
        await queryMe();
      }

      if (!token && user.isLoggedIn) {
        user.logout();
        await apolloClient.resetStore();
      }
      user.loading = false;
    }

    async function queryMe(): Promise<void> {
      try {
        const { data } = await apolloClient.query<GqlMeConsumerQuery>({ query: meConsumer, fetchPolicy: `no-cache` });

        user.setUser(data.meConsumer);
        user.refetchRequest = false;

        if (data.meConsumer?.profile.address && !cart.hasAddress) {
          cart.updateAddress({ location: data.meConsumer.profile.address, residentialStatus: 'VERIFIED' });
        }
      } catch (err) {
        console.error(err);
      }
      user.loading = false;
    }

    void checkUser();
  }, [apolloClient, user, token, refetchRequest, cart]);
}

export function useStorageListener({ user }): void {
  // listen for login events on other windows
  useEffect(() => {
    if (!isBrowser()) {
      // ssr
      return;
    }
    function handleStorageEvent(event): void {
      if (event.key === 'access-token') {
        user.token = JSON.parse(event.newValue);
      }
    }
    window.addEventListener('storage', handleStorageEvent);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('storage', handleStorageEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // FIXME: ENG-32714 fix hooks dependency
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function useSendToAgeVerificationGate({ user, UI }): void {
  const { isEmbedded, isStoreFront, isAccountPage, isLocationSelectorPage } = UI;
  const isWrongVariant = !isEmbedded && !isStoreFront;
  const userId = useObserver(() => user.id);
  const token = useObserver(() => user.token);
  const { AGE_VERIFICATION_GATE } = ROUTES;

  const { dispensary } = useDispensary();
  const { href } = usePaths({ dispensary });
  const isAgeVerificationSettingEnabled = dispensary?.storeSettings.hardAgeGateAgeVerification ?? false;
  const router = useRouter();

  const { isAgeVerified, loading } = useCheckAgeVerification({ dispensary, userId });

  const skipRedirectToAgeGate =
    isWrongVariant || !isAgeVerificationSettingEnabled || !dispensary || isAccountPage || isLocationSelectorPage;

  useEffect(() => {
    if (skipRedirectToAgeGate) {
      return;
    }

    async function getIsUserAgeVerified(): Promise<void> {
      const notVerified = !loading && !isAgeVerified;

      const notLoggedIn = !token;

      if (notVerified || notLoggedIn) {
        void router.replace(`${href}${AGE_VERIFICATION_GATE}`);
      }
    }

    void getIsUserAgeVerified();
    // we don't want router here or we get infinite renders when we push to the router stack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AGE_VERIFICATION_GATE, href, isAgeVerified, loading, skipRedirectToAgeGate, token]);
}

export default function useUserController(): void {
  const user = useUser();
  const cart = useCart();
  const UI = useUI();

  const { dispensary: viewingDispensary } = useDispensary();
  const dispensary = useObserver(() => viewingDispensary || cart.dispensary);

  usePersistedValue(user, `userDetailsFromExternalSource`);
  useUserQuery({ user, cart });
  useStorageListener({ user });
  // eslint-disable-next-line @typescript-eslint/naming-convention
  useSendToAgeVerificationGate({ user, UI });
  useLaunchDarklyClientContext({ user, dispensary });
  useSetSessionUtm({ dispensary });
}
