import _some from "lodash/some";
import _keys from "lodash/keys";
import _forEach from "lodash/forEach";
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { redistributePennies } from '../utilities';
import { calculateSaleDiscounts } from '../sales';
import { calculateBogoDiscounts, determineBogoDiscounts } from '../bogo';
import { calculateCouponDiscounts } from '../coupons';
import { compareStowedDiscounts } from '../discount-comparison';
import { calculateRewardDiscounts } from '../rewards';
import { redistributeDiscountToCart } from '../bogo/redistribute-discount-to-cart';
import { calculateStackingCompoundingPercentDtcLegacy } from '../bogo/calculate-stacking-compounding-percent-dtc-legacy';
import { calculateStackingCompoundingPercentDtc } from '../bogo/calculate-stacking-compounding-percent-dtc';
import { checkAndUpdateMixAndMatch, getUpdatedTaxes } from './other-helpers';
import { getAdjustedBasePrice } from './helpers';
export function calculateProductDiscountsEcomm(_ref) {
  var avoidTaxes = _ref.avoidTaxes,
      bottleDepositTaxCentsTotal = _ref.bottleDepositTaxCentsTotal,
      cannabisTaxTotal = _ref.cannabisTaxTotal,
      coupon = _ref.coupon,
      couponApplicableSubtotal = _ref.couponApplicableSubtotal,
      couponApplicableSubtotalPlusTaxes = _ref.couponApplicableSubtotalPlusTaxes,
      couponLeftOver = _ref.couponLeftOver,
      credit = _ref.credit,
      detailsSorted = _ref.detailsSorted,
      discountTaxOrder = _ref.discountTaxOrder,
      globalSpecialsSettings = _ref.globalSpecialsSettings,
      hasIndividualNonStackingSpecial = _ref.hasIndividualNonStackingSpecial,
      hasTotalQtyCalcsFix = _ref.hasTotalQtyCalcsFix,
      medicalOrder = _ref.medicalOrder,
      products = _ref.products,
      productTotal = _ref.productTotal,
      receipt = _ref.receipt,
      residual = _ref.residual,
      residualPrice = _ref.residualPrice,
      rewards = _ref.rewards,
      rewardApplicableSubtotal = _ref.rewardApplicableSubtotal,
      rewardApplicableSubtotalPlusTaxes = _ref.rewardApplicableSubtotalPlusTaxes,
      rewardAppliedToCredit = _ref.rewardAppliedToCredit,
      rewardCredit = _ref.rewardCredit,
      rewardLeftOver = _ref.rewardLeftOver,
      salesTaxTotal = _ref.salesTaxTotal;
  var bogoCalcsData = {};
  var bogoRewardsApplied = {};
  bogoCalcsData = determineBogoDiscounts({
    bogoCalcsData: bogoCalcsData,
    detailsSorted: detailsSorted,
    discountTaxOrder: discountTaxOrder,
    medicalOrder: medicalOrder,
    globalSpecialsSettings: globalSpecialsSettings
  });

  _forEach(detailsSorted, function (detail, index) {
    var _products$key, _detail$mixAndMatch, _bogoCalcsData, _bogoCalcsData$applic;

    var _detail = detail,
        quantity = _detail.quantity,
        specialData = _detail.specialData,
        wholesalePrice = _detail.wholesalePrice,
        key = _detail.key;
    var basePrice = getAdjustedBasePrice(detail);
    var product = (_products$key = products[key]) === null || _products$key === void 0 ? void 0 : _products$key.product;

    if (!product) {
      console.warn("can't find product ".concat(key));
      return;
    }

    var receiptDetail = receipt.addProduct(detail.id, quantity, basePrice.times(100), detail.option);
    checkAndUpdateMixAndMatch(receiptDetail, {
      originalBasePrice: detail.basePrice,
      adjustedBasePrice: basePrice,
      mixAndMatchDiscountAmount: Big(((_detail$mixAndMatch = detail.mixAndMatch) === null || _detail$mixAndMatch === void 0 ? void 0 : _detail$mixAndMatch.discountAmount) || 0)
    });
    var applicableBogoRewards = ((_bogoCalcsData = bogoCalcsData) === null || _bogoCalcsData === void 0 ? void 0 : (_bogoCalcsData$applic = _bogoCalcsData.applicableRewards) === null || _bogoCalcsData$applic === void 0 ? void 0 : _bogoCalcsData$applic[key]) || [];
    var price = basePrice.times(quantity);
    var qtyWholesalePrice = wholesalePrice.times(quantity);
    var whPrice = qtyWholesalePrice.round(2, 1);
    var bottleDepositTaxCents = Big(0);
    var cannabisTax = Big(0);
    var salesTax = Big(0);

    if (discountTaxOrder === 'both' || discountTaxOrder === 'taxesFirst') {
      var _getUpdatedTaxes = getUpdatedTaxes({
        detail: detail,
        price: price,
        productNonArmsLength: product.nonArmsLength,
        whPrice: whPrice,
        product: product
      });

      bottleDepositTaxCents = _getUpdatedTaxes.bottleDepositTaxCents;
      cannabisTax = _getUpdatedTaxes.cannabisTax;
      salesTax = _getUpdatedTaxes.salesTax;

      var _redistributePennies = redistributePennies({
        cannabisTax: cannabisTax,
        price: price,
        salesTax: salesTax
      });

      cannabisTax = _redistributePennies.cannabisTax;
      salesTax = _redistributePennies.salesTax;
      residual = _redistributePennies.residual;
    }

    if (discountTaxOrder === 'taxesFirst') {
      // If the tax is taken before the discount then add it into the subtotal much
      // as we do above with the discount.
      if (Big(detail.basePriceMult, 'Base Price Mult').eq(1)) {
        bottleDepositTaxCentsTotal = bottleDepositTaxCentsTotal.add(bottleDepositTaxCents);
        cannabisTaxTotal = cannabisTaxTotal.add(cannabisTax);
        salesTaxTotal = salesTaxTotal.add(salesTax);
      } else {
        // Unclear how to deal with residual taxes in this case.
        productTotal = productTotal.add(cannabisTax).add(salesTax).add(bottleDepositTaxCents.div(100));
        avoidTaxes = true;
      }

      residualPrice = residualPrice.add(residual);
    }

    var postTaxPrice = price.plus(cannabisTax).plus(salesTax).plus(bottleDepositTaxCents.div(100));
    var compositePrice = discountTaxOrder === 'taxesFirst' ? Big(postTaxPrice) : Big(price);
    var originalCompositePrice = Big(compositePrice);
    var stowedDiscounts = [];

    var calculatePromotionDiscounts = function calculatePromotionDiscounts(stowDiscounts, applyStowedDiscounts) {
      var _calculateSaleDiscoun = calculateSaleDiscounts({
        applyStowedDiscounts: applyStowedDiscounts,
        compositePrice: compositePrice,
        credit: credit,
        detail: detail,
        discountTaxOrder: discountTaxOrder,
        originalCompositePrice: originalCompositePrice,
        medicalOrder: medicalOrder,
        productTotal: productTotal,
        receiptDetail: receiptDetail,
        specialData: specialData,
        globalSpecialsSettings: globalSpecialsSettings,
        stowDiscounts: stowDiscounts,
        stowedDiscounts: stowedDiscounts
      });

      compositePrice = _calculateSaleDiscoun.compositePrice;
      credit = _calculateSaleDiscoun.credit;
      productTotal = _calculateSaleDiscoun.productTotal;
      stowedDiscounts = _calculateSaleDiscoun.stowedDiscounts;

      var _calculateBogoDiscoun = calculateBogoDiscounts({
        applicableBogoRewards: applicableBogoRewards,
        applyStowedDiscounts: applyStowedDiscounts,
        bogoRewardsApplied: bogoRewardsApplied,
        compositePrice: compositePrice,
        credit: credit,
        detail: detail,
        discountTaxOrder: discountTaxOrder,
        originalCompositePrice: originalCompositePrice,
        productTotal: productTotal,
        receiptDetail: receiptDetail,
        globalSpecialsSettings: globalSpecialsSettings,
        stowDiscounts: stowDiscounts,
        stowedDiscounts: stowedDiscounts
      });

      bogoRewardsApplied = _calculateBogoDiscoun.bogoRewardsApplied;
      compositePrice = _calculateBogoDiscoun.compositePrice;
      credit = _calculateBogoDiscoun.credit;
      productTotal = _calculateBogoDiscoun.productTotal;
      stowedDiscounts = _calculateBogoDiscoun.stowedDiscounts;

      var _calculateCouponDisco = calculateCouponDiscounts({
        applyStowedDiscounts: applyStowedDiscounts,
        compositePrice: compositePrice,
        coupon: coupon,
        couponApplicableSubtotal: couponApplicableSubtotal,
        couponApplicableSubtotalPlusTaxes: couponApplicableSubtotalPlusTaxes,
        couponLeftOver: couponLeftOver,
        credit: credit,
        detail: detail,
        discountTaxOrder: discountTaxOrder,
        originalCompositePrice: originalCompositePrice,
        productTotal: productTotal,
        receiptDetail: receiptDetail,
        globalSpecialsSettings: globalSpecialsSettings,
        stowDiscounts: stowDiscounts,
        stowedDiscounts: stowedDiscounts
      });

      compositePrice = _calculateCouponDisco.compositePrice;
      couponLeftOver = _calculateCouponDisco.couponLeftOver;
      credit = _calculateCouponDisco.credit;
      productTotal = _calculateCouponDisco.productTotal;
      stowedDiscounts = _calculateCouponDisco.stowedDiscounts;
    };

    var shouldCompareDiscounts = globalSpecialsSettings.discountStacking === false || hasIndividualNonStackingSpecial; // If discountStacking is explicitly set to false run to determine winningDiscount, otherwise apply defaults

    calculatePromotionDiscounts(shouldCompareDiscounts); // If discountStacking is explicitly set to false re-run while applying the winningDiscount

    if (shouldCompareDiscounts) {
      var _compareStowedDiscoun = compareStowedDiscounts({
        bogoCalcsData: bogoCalcsData,
        detail: detail,
        stowedDiscounts: stowedDiscounts,
        globalSpecialsSettings: globalSpecialsSettings,
        hasTotalQtyCalcsFix: hasTotalQtyCalcsFix
      });

      bogoCalcsData = _compareStowedDiscoun.bogoCalcsData;
      detail = _compareStowedDiscoun.detail;
      stowedDiscounts = _compareStowedDiscoun.stowedDiscounts;
      calculatePromotionDiscounts(false, true);
    }

    var _calculateRewardDisco = calculateRewardDiscounts({
      compositePrice: compositePrice,
      credit: credit,
      detail: detail,
      discountTaxOrder: discountTaxOrder,
      originalCompositePrice: originalCompositePrice,
      productTotal: productTotal,
      receiptDetail: receiptDetail,
      rewardApplicableSubtotal: rewardApplicableSubtotal,
      rewardApplicableSubtotalPlusTaxes: rewardApplicableSubtotalPlusTaxes,
      rewardCredit: rewardCredit,
      rewardLeftOver: rewardLeftOver,
      rewards: rewards,
      rewardAppliedToCredit: rewardAppliedToCredit
    });

    compositePrice = _calculateRewardDisco.compositePrice;
    credit = _calculateRewardDisco.credit;
    productTotal = _calculateRewardDisco.productTotal;
    rewardCredit = _calculateRewardDisco.rewardCredit;
    rewardLeftOver = _calculateRewardDisco.rewardLeftOver;
    rewardAppliedToCredit = _calculateRewardDisco.rewardAppliedToCredit;

    if (discountTaxOrder === 'discountsFirst') {
      bottleDepositTaxCents = detail.bottleDepositTaxCents.times(detail.quantity);
      cannabisTax = detail.cannabisTax(compositePrice, whPrice, product, detail.option);
      salesTax = detail.salesTax(compositePrice, whPrice, product, detail.option);

      var _redistributePennies2 = redistributePennies({
        cannabisTax: cannabisTax,
        price: compositePrice,
        salesTax: salesTax
      });

      cannabisTax = _redistributePennies2.cannabisTax;
      salesTax = _redistributePennies2.salesTax;
      residual = _redistributePennies2.residual;
    }

    if (discountTaxOrder === 'both' || discountTaxOrder === 'discountsFirst') {
      bottleDepositTaxCentsTotal = bottleDepositTaxCentsTotal.add(bottleDepositTaxCents);
      cannabisTaxTotal = cannabisTaxTotal.add(cannabisTax);
      salesTaxTotal = salesTaxTotal.add(salesTax);
      residualPrice = residualPrice.add(residual);
    }

    receiptDetail.addTax({
      type: 'bottleDeposit',
      value: bottleDepositTaxCents
    });
    receiptDetail.addTax({
      type: 'cannabis',
      value: cannabisTax.times(100)
    });
    receiptDetail.addTax({
      type: detail.isVapeTaxed ? 'vape' : 'sales',
      value: salesTax.times(100)
    });

    if (bogoCalcsData.redetermineDiscounts) {
      delete bogoCalcsData.applicableRewards;
      delete bogoCalcsData.conditionsSatisfiers;
      delete bogoCalcsData.rewardsSatisfiers;
      delete bogoCalcsData.discountToCartRewards;
      delete bogoCalcsData.discountToCartSatisfiers;
      bogoCalcsData = determineBogoDiscounts({
        bogoCalcsData: bogoCalcsData,
        detailsSorted: detailsSorted,
        discountTaxOrder: discountTaxOrder,
        medicalOrder: medicalOrder,
        globalSpecialsSettings: globalSpecialsSettings
      });
      /*
      Before discount to cart, if we were calculating the discount on the last item in the cart and one of the specials
      was defeated, we would simply discard it. In the case of discount to cart, if we are on the last item and a DTC
      offer is defeated that means we need to redistribute the discount to the applicable items. This means we need to
      rerun the calculation one last time :pray:
      */

      var checkingLastItem = detailsSorted.length === index + 1 && detailsSorted.length > 1;

      var keysToApplyDiscount = _keys(bogoCalcsData.discountToCartRewards);

      var atLeastOneItemCanReceiveDiscountToCart = keysToApplyDiscount.length > 0;

      if (checkingLastItem && atLeastOneItemCanReceiveDiscountToCart) {
        var adjustment = redistributeDiscountToCart({
          keysToApplyDiscount: keysToApplyDiscount,
          detailsSorted: detailsSorted,
          bogoCalcsData: bogoCalcsData,
          products: products,
          discountTaxOrder: discountTaxOrder,
          bottleDepositTaxCentsTotal: bottleDepositTaxCentsTotal,
          cannabisTaxTotal: cannabisTaxTotal,
          salesTaxTotal: salesTaxTotal,
          productTotal: productTotal,
          avoidTaxes: avoidTaxes,
          residualPrice: residualPrice,
          credit: credit,
          bogoRewardsApplied: bogoRewardsApplied,
          compositePrice: compositePrice,
          stowedDiscounts: stowedDiscounts,
          receiptDetail: receiptDetail,
          globalSpecialsSettings: globalSpecialsSettings
        });
        bottleDepositTaxCentsTotal = adjustment.bottleDepositTaxCentsTotal;
        cannabisTaxTotal = adjustment.cannabisTaxTotal;
        salesTaxTotal = adjustment.salesTaxTotal;
        productTotal = adjustment.productTotal;
        avoidTaxes = adjustment.avoidTaxes;
        residualPrice = adjustment.residualPrice;
        credit = adjustment.credit;
        bogoRewardsApplied = adjustment.bogoRewardsApplied;
        compositePrice = adjustment.compositePrice;
        stowedDiscounts = adjustment.stowedDiscounts;
      }
    }
  }); // TODO: remove with core.cats-ecomm.better-potency-tax-on-receipt.rollout


  var useNewDTCPath = _some(detailsSorted, function (detail) {
    return detail.useBetterPotencyTaxes;
  });

  if (useNewDTCPath) {
    var _calculateStackingCom = calculateStackingCompoundingPercentDtc({
      applicableRewards: bogoCalcsData.applicableRewards,
      cartProducts: products,
      credit: credit,
      detailItems: detailsSorted,
      discountTaxOrder: discountTaxOrder,
      discountToCartSatisfiers: bogoCalcsData.discountToCartSatisfiers,
      receipt: receipt,
      bottleDepositTaxCentsTotal: bottleDepositTaxCentsTotal,
      cannabisTaxTotal: cannabisTaxTotal,
      salesTaxTotal: salesTaxTotal
    });

    credit = _calculateStackingCom.credit;
    bottleDepositTaxCentsTotal = _calculateStackingCom.bottleDepositTaxCentsTotal;
    cannabisTaxTotal = _calculateStackingCom.cannabisTaxTotal;
    salesTaxTotal = _calculateStackingCom.salesTaxTotal;
  } else {
    var _calculateStackingCom2 = calculateStackingCompoundingPercentDtcLegacy({
      applicableRewards: bogoCalcsData.applicableRewards,
      cartProducts: products,
      credit: credit,
      detailItems: detailsSorted,
      discountTaxOrder: discountTaxOrder,
      discountToCartSatisfiers: bogoCalcsData.discountToCartSatisfiers,
      receipt: receipt,
      bottleDepositTaxCentsTotal: bottleDepositTaxCentsTotal,
      cannabisTaxTotal: cannabisTaxTotal,
      salesTaxTotal: salesTaxTotal
    });

    credit = _calculateStackingCom2.credit;
  }

  return {
    avoidTaxes: avoidTaxes,
    bottleDepositTaxCentsTotal: bottleDepositTaxCentsTotal,
    cannabisTaxTotal: cannabisTaxTotal,
    credit: credit,
    productTotal: productTotal,
    residualPrice: residualPrice,
    rewardAppliedToCredit: rewardAppliedToCredit,
    rewardCredit: rewardCredit,
    salesTaxTotal: salesTaxTotal
  };
}