import { ModalComponentProps } from '../../with-modal-controls-hook';

export type DispensaryStrainTypeOption = 'Hybrid' | 'Indica' | 'Sativa';

export enum DispensaryStrainTypeOptionsQueryParamMap {
  indica = `Indica`,
  sativa = `Sativa`,
  hybrid = `Hybrid`,
}

export const DISPENSARY_STRAIN_TYPE_OPTIONS = [
  {
    key: `indica`,
    label: `Indica`,
    labelTranslationKey: `strainType.indica`,
    value: DispensaryStrainTypeOptionsQueryParamMap.indica,
  },
  {
    key: `sativa`,
    label: `Sativa`,
    labelTranslationKey: `strainType.sativa`,
    value: DispensaryStrainTypeOptionsQueryParamMap.sativa,
  },
  {
    key: `hybrid`,
    label: `Hybrid`,
    labelTranslationKey: `strainType.hybrid`,
    value: DispensaryStrainTypeOptionsQueryParamMap.hybrid,
  },
];

export type DispensaryStrainTypeFilterDrawerProps = ModalComponentProps & {
  onSave: () => void;
  onSelect: (option: string) => void;
  options: typeof DISPENSARY_STRAIN_TYPE_OPTIONS;
  selectedOptions: DispensaryStrainTypeOption[];
};

export type UseDispensaryStrainTypeProps = {
  open: boolean;
};

export type UseDispensaryStrainTypeReturn = {
  handleSave: () => void;
  handleSelect: (option: string) => void;
  selectedOptions: DispensaryStrainTypeOption[];
};
