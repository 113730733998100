import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import findIndex from 'lodash/findIndex';

import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';

import { hasSaleSpecialForMenuType, productSatisfiesSaleWeight } from 'shared/helpers/specials';
import { groupOptionsWithPrices } from 'utils/helpers/product';
import { discountAmount, getProductWeight } from 'shared/helpers/products';

const useProductPrices = (product, option) => {
  const cart = useCart();
  const UI = useUI();
  const menuType = useObserver(() => cart.menuType);
  const isKiosk = useObserver(() => UI.isKiosk);

  if (!product) {
    return {};
  }

  const sanitizedOption = option?.replace(/_/g, '.')?.replace(/-/g, '/');
  const { Options: weights = [] } = product || {};

  const optionsWithStandardPrices = groupOptionsWithPrices({ product, menuType, isKiosk, saleSpecial: false });
  const optionsWithSpecialPrices = groupOptionsWithPrices({ product, menuType, isKiosk, saleSpecial: true }); // or here
  // if we don't have any restrictions on this special, then return the special prices
  const availableWeights = _.filter(
    weights,
    (weight) =>
      !!(_.find(optionsWithStandardPrices, [`value`, weight]) || !!_.find(optionsWithSpecialPrices, [`value`, weight]))
  );

  const weightedSpecialPrices = _.map(availableWeights, (formattedWeight) => {
    const weight = getProductWeight(formattedWeight);
    let specialIndex = findIndex(optionsWithSpecialPrices, { value: formattedWeight });
    specialIndex = specialIndex < 0 ? 0 : specialIndex;
    let regIndex = findIndex(optionsWithStandardPrices, { value: formattedWeight });
    regIndex = regIndex < 0 ? 0 : regIndex;
    const satisfiesWeightCondition = product?.specialData?.saleSpecials
      ? productSatisfiesSaleWeight(product, product.specialData.saleSpecials, formattedWeight)
      : true;

    if (satisfiesWeightCondition) {
      return {
        discountPercentage: Number(
          discountAmount(optionsWithStandardPrices[regIndex].price, optionsWithSpecialPrices[specialIndex].price)
        ),
        price: optionsWithSpecialPrices[specialIndex].price,
        weight,
        formattedWeight,
        standardPrice: optionsWithStandardPrices[regIndex].price,
      };
    }
    return { discountPercentage: 0, price: optionsWithStandardPrices[regIndex].price, weight };
  });

  const firstSaleWeight = _.find(weightedSpecialPrices, (s) => s.discountPercentage > 0);
  let displayOptionIndex = findIndex(optionsWithStandardPrices, {
    value: sanitizedOption || firstSaleWeight?.formattedWeight,
  });
  if (displayOptionIndex === -1) {
    displayOptionIndex = 0;
  }
  const standardPrice = Number(optionsWithStandardPrices?.[displayOptionIndex]?.price || 0);
  let specialPrice = null;
  let discountPercentage = 0;

  if (hasSaleSpecialForMenuType({ product, menuType })) {
    specialPrice = Number(optionsWithSpecialPrices?.[displayOptionIndex]?.price || 0);
    discountPercentage = Number(discountAmount(standardPrice, specialPrice));
  }

  return {
    standardPrice,
    specialPrice,
    displayOption: sanitizedOption || product.Options?.[displayOptionIndex],
    discountPercentage, // global percentage if applicable
    weightedSpecialPrices,
  };
};

export default useProductPrices;
