import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Imgix from 'shared/components/imgix';
import _ from 'lodash';

import { DefaultImages, ImgixDefaultSettings } from 'shared/constants';
import { imageToUse } from 'shared/helpers/products';

export default function ProductImage({ product, onLoaded = _.noop, ...imageProps }) {
  const [imageHasError, setImageHasError] = useState(false);

  const defaultImage = _.get(DefaultImages, product?.type, DefaultImages.Flower);
  let resolvedImgSrc = imageHasError ? defaultImage : imageToUse(product || {});

  const onImageError = () => {
    setImageHasError(true);
    console.log(`Image failed to load.`, {
      imgSrc: imageToUse(product || {}),
      product: { id: product?._id },
    });
  };

  const onMounted = useCallback((img) => {
    img.onerror = onImageError;
    if (_.isFunction(onLoaded)) {
      img.onload = () => {
        onLoaded();
      };
    }
  }, []);

  return (
    <StyledImgix
      {...imageProps}
      type='img'
      imgixParams={ImgixDefaultSettings}
      sizes='auto'
      htmlAttributes={{
        alt: product?.description,
        loading: 'lazy',
        decoding: 'async',
      }}
      src={resolvedImgSrc || defaultImage}
      onMounted={onMounted}
    />
  );
}

const StyledImgix = styled(Imgix)`
  object-fit: contain;
`;
